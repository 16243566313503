<template>
  <div>
    <b-card class="text-black">
      <b-card-body class="mt-2">
        <b-row
          class="mt-2 mb-2"
          style="border: 1px solid #ccc; border-radius: 5px; padding: 10px"
        >
          <b-col md="2">
            <b-form-group label="CNIC">
              <b-form-input
                id="cnic"
                v-model="cnic"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="CNIC"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="2">
            <b-form-group label="Name">
              <b-form-input
                id="Name"
                v-model="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                placeholder="Name"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="2">
            <b-form-group label="Category">
              <v-select
                v-model="category"
                :options="categories"
                class="vSelectStyle bg-white"
                placeholder="Select category"
                style="color:black !important;"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Hospital">
              <v-select
                v-model="selectedHospital"
                :options="hospitalList"
                class="vSelectStyle bg-white"
                placeholder="Select hospital"
                label="option"
                style="color:black !important;"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col class="text-right mb-1" style="margin: auto">
            <div style="display: flex; justify-content: flex-end">
              <b-button
                variant="primary"
                pill
                style="margin-right: 2%"
                @click="searchFeedbackViaSearchButton"
              >
                <feather-icon icon="SearchIcon" class="mr-50" />
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-table
          responsive="sm"
          :fields="fields"
          :paginated="true"
          :items="Feedbacks"
          details-td-class="m-0 p-0"
          sticky-header="700px"
          small
        >
          <template #cell(manage)="row">
            <b-button
              variant="info"
              @click="viewSuggestion(row.item)"
              pill
              size="sm"
            >
              view</b-button
            >
          </template>
        </b-table>
        <b-row>
          <b-col md="8" class="pagination">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDataLength"
              :per-page="per_page"
            />
          </b-col>
          <b-col md="4">
            <div style="float: right">
              <h5 style="margin: 0; display: inline" class="text-primary">
                Count:
              </h5>
              <h5 style="margin: 0; display: inline" align="right">
                <strong>
                  {{ totalDataLength }}
                </strong>
              </h5>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
export default {
  components: {},
  data() {
    return {
      Feedbacks: [],
      hospitalList: [],
      selectedHospital: null,
      feedback: null,
      mobile: null,
      cnic: null,
      // name: null,
      totalDataLength: 0,
      per_page: 0,
      searchOptions: [
        { text: "Yes", value: "True" },
        { text: "No", value: "False" },
      ],

      show: false,
      currentPage: 1,
      suggestionModalCount: 0,
      draft: false,
      petitionID: null,
      category: null,
      categories: [
        "Shuhada Family",
        "Retired Officer",
        "Retired Officer Family",
        // "Retired JCOs / Soilder",
        // "Retired JCOs / Soilder Family",
      ],
      fields: [
        // { key: "cnic", label: "CNIC", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "mobile", label: "Mobile", sortable: true },
        { key: "category", label: "Category", sortable: true },
        { key: "hospital_name", label: "Hospital", sortable: true },
        { key: "communication", label: "Communication", sortable: true },
        { key: "transportation_reception", label: "Reception", sortable: true },
        {
          key: "wheel_chair_stretcher",
          label: "Wheel Chair / Stretcher",
          sortable: true,
        },
        { key: "facilitation_SVMFC", label: "Facilitation", sortable: true },
        { key: "lab_xray_medicine", label: "Lab Test", sortable: true },
        {
          key: "fixing_appointment",
          label: "Appointment Fixing",
          sortable: true,
        },
        {
          key: "nursing_accompanied",
          label: "Nursing Assistant",
          sortable: true,
        },
        {
          key: "reports_collection",
          label: "Reports Collection",
          sortable: true,
        },
        {
          key: "transportation_on_return",
          label: "Transportation on Return",
          sortable: true,
        },
        {
          key: "conduct_doc_self_family",
          label: "Doctor's Conduct",
          sortable: true,
        },
        {
          key: "conduct_dress_SVMFC_staff",
          label: "Staff's Conduct",
          sortable: true,
        },
        { key: "manage", label: "Suggestion" },
      ],
    };
  },
  mixins: [util],
  async mounted() {
    await this.getFeedbackData();
    await this.getHospitals();
  },

  methods: {
    ...mapActions({
      getFeedbacks: "appData/getFeedbacks",
      hospitals: "appData/hospitals",
    }),

    async getHospitals() {
      const res = await this.hospitals();
      const hospitalsData = res.data.results;
      this.hospitalList = hospitalsData.map((hosp) => ({
        value: hosp.id,
        option: hosp.name,
      }));
    },
    async searchFeedbackViaSearchButton() {
      this.currentPage = 1;
      await this.searchFeedbackWithFilters();
    },
    viewSuggestion(feedback) {
      this.$swal({
        title: "Suggestion",
        width: "auto",
        html: `<img src="${feedback.other_observation_suggestion}" alt="Suggestion Image" style="width: 600px; height:400px;">`,
      });
    },

    async searchFeedbackWithFilters() {
      let payload = {};
      payload["pageNumber"] = this.currentPage;
      // if (this.name !== null) {
      //   payload["name"] = this.name;
      // }
      if (this.cnic !== null) {
        payload["cnic"] = this.cnic;
      }
      if (this.category !== null) {
        payload["category"] = this.category;
      }
      if (this.selectedHospital !== null) {
        payload["hospital"] = this.selectedHospital.value;
      }
      await this.getFeedbackData(payload);
    },

    async getFeedbackData(searchParams = {}) {
      try {
        this.show = true;
        const res = await this.getFeedbacks(searchParams);
        this.Feedbacks = res.data.results;
        this.per_page = res.data.per_page;
        this.totalDataLength = res.data.count;
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
  },

  watch: {
    currentPage: {
      async handler() {
        await this.searchFeedbackWithFilters();
      },
    },
  },
};
</script>
<style scoped>
.text-black {
  color: black !important;
}
::placeholder {
  color: black;
}
label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}
</style>
<style lang="scss">
@import "~@assets/scss/variables/_variables.scss";
</style>
